@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

*{
  font-family: 'Poppins', sans-serif !important;
  }
.App {
  text-align: center;
  font-family: "Poppins", sans-serif;
  background-color: #F4F8FB !important;
  overflow-y: scroll !important;
  overflow-x: hidden;
}
button, a {
  cursor: pointer !important;
}

@media screen and (max-width: 500px) {
  body{
    overflow-y: auto;
  }
}
/*FIRST PAGE*/
.first-page{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  flex-direction: column;
  min-height: 100vh;
  background-image: url(./imgs/back.png);
  color: white;
  background-size: cover;
  padding: 20px 0;
}
.first-page > h1 {
  width: 60%;
  margin-top: 50px;
}
.first-page .dente{
  width: 50px;
}
.first-page > p:first-of-type{
  margin-top: 20px;
}
.cap-input{
  background-color: white;
  color: #213846;
  border-radius: 5px;
  padding: 4px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.cap-input > input{
  background-color: transparent;
  border: none;
  outline: none;
}
.first-page button {
  background-color: #3985F7;
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin: 20px 0;
}
.first-page > img:first-of-type{
  margin: 20px 0;
}

/*RESULTS*/
.results{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  flex-direction: column;
  min-height: 100vh;
  background-image: url(./imgs/back.png);
  color: white;
  background-size: cover;
  padding: 0px 0 40px 0;
}
.results > h1{
  width: 80%;
  margin-top: 50px;
}
.results > hr{
  color: white;
  background-color: white;
  border: 1px solid white;
  z-index: 10;
  height: 1.5px;
  width: 50%;
  margin-top: 0px;
}
.results-bottom{
  background-color: white;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 68vh;
  color: #07476D;
}
.results-bottom > img:first-of-type{
  width: 70px;
  margin-top: 30px;
}
.results-bottom > h2{
  margin-top: 20px;
}
.results-bottom > p:first-of-type{
  width: 80%;
  margin: 0 auto;
  margin-top: 15px;
}
.results-bottom > p:nth-of-type(2){
  margin-top: 40px;
}
.results-bottom > input,
.results > input{
  background-color: #dbd7ed;
  color: #213846;
  border-radius: 20px;
  padding: 6px 12px;
  margin: 20px 0 10px 0;
  outline: none;
  border: none;
}
.results-bottom > button, .results > button {
  background-color: #3985F7;
  color: white;
  padding: 8px 14px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 5px;
}


/*RESULTS2*/
.div1{
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 20px;
}
.div1 > p{
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
  font-size: 14px;
}
.div1 > p > span {
  margin-left: 10px;
  background-color: #3471cc62;
  border-radius: 20px;
  padding: 4px 8px;
  text-decoration: none !important;
}
.div1 > img {
  width: 23%;
}
.div2{
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  font-size: 14px;
  margin-top: -15px;
}
.div2 > p:first-of-type{
  width: 40%;
  text-align: left;
}
.div2 > p:last-of-type{
  width: 40%;
  text-align: right;
  font-size: 12px;
}


.recensioni-header{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 30px;
}
.recensioni-header > p {
  font-size: 14px;
  text-decoration: underline;
  margin-top: 5px;
}
.recensioni-body{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  margin-top: 10px;
}
.recensioni-body > img {
  width: 27%;
}


.resultImg{
  width: 90%;
}

.loading-page{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  min-height: 100vh;
  background-image: url(./imgs/back.png);
  color: white;
  background-size: cover;
}
.loading-text{
  font-size: 28px;
  width: 70%;
  margin-top: 20px;
}
.loader{
  background-color: #FFFFFF;
  border-radius: 30px;
  width: 80%;
  padding: 3px 4px;
  margin-top: 30px;
}
.progress{
  border-radius: 30px;
}
.spinner {
  border: 4px solid #3471cc62;
  border-left-color: #f5f5f5;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}